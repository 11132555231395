<template>
  <b-row>
    <b-col sm="3">
      <b-card
        class="bg-success-fade height-card"
      >
        <h4 class="mb-1 d-inline-block">
          <strong class="mr-1">{{ $t('Facility') }}</strong>
        </h4>
        <b-badge variant="success">
          {{ $t('Ready') }}
        </b-badge>
        <h2 class="mb-1">
          <strong>{{ this.readyCurrent }}</strong>
        </h2>
      </b-card>
    </b-col>
    <b-col sm="3">
      <b-card
        class="bg-warning-fade height-card"
      >
        <h4 class="mb-1 d-inline-block">
          <strong class="mr-1">{{ $t('Facility') }}</strong>
        </h4>
        <b-badge variant="warning">
          {{ $t('Inactive') }}
        </b-badge>
        <h2 class="mb-1">
          <strong>{{ this.inactiveCurrent }}</strong>
        </h2>
      </b-card>
    </b-col>
    <b-col sm="3">
      <b-card
        class="bg-light-fade height-card"
      >
        <h4 class="mb-1 d-inline-block">
          <strong class="mr-1">{{ $t('Facility') }}</strong>
        </h4>
        <b-badge variant="light-dark">
          {{ $t('MaintenanceExpired') }}
        </b-badge>
        <h2 class="mb-1">
          <strong>{{ this.MaintenanceExpiredCurrent }}</strong>
        </h2>
      </b-card>
    </b-col>
    <b-col sm="3">
      <b-card
        class="bg-danger-fade height-card"
      >
        <h4 class="mb-1 d-inline-block">
          <strong class="mr-1">{{ $t('Facility') }}</strong>
        </h4>
        <b-badge variant="danger">
          {{ $t('ConIncidencia') }}
        </b-badge>
        <h2 class="mb-1">
          <strong>{{ this.incidenceCurrent }}</strong>
        </h2>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BBadge,
  },
  props: {
    categoryId: {},
    clientprop: {},
    searchProp: {},
    locationProp: {},
    albaranProp: {},
    statusProp: {},
    vida_utilProp: {},
    prox_revisionProp: {},
    is_epiProp: {},
    userProp: {},
    dateIniProp: {},
    dateFinProp: {},
  },
  data() {
    return {
      type: 'line',
    }
  },
  computed: {
    ...mapGetters({
      currentClient: 'clients/getCurrentClient',
      dashboardAssets: 'actions/getDashboardAssets',
    }),
    readyCurrent() {
      if (this.dashboardAssets.ready) {
        return this.dashboardAssets.ready.current
      }
      return 0
    },
    inactiveCurrent() {
      if (this.dashboardAssets.low) {
        return this.dashboardAssets.low.current
      }
      return 0
    },
    MaintenanceExpiredCurrent() {
      if (this.dashboardAssets.expired_maintenance) {
        return this.dashboardAssets.expired_maintenance.current
      }
      return 0
    },
    incidenceCurrent() {
      if (this.dashboardAssets.incidence) {
        return this.dashboardAssets.incidence.current
      }
      return 0
    },
  },
  watch: {
    categoryId(){
      this.chargeData()
    },
    clientProp() {
      this.chargeData()
    },
    searchProp() {
      this.chargeData()
    },
    locationProp() {
      this.chargeData()
    },
    albaranProp() {
      this.chargeData()
    },
    statusProp() {
      this.chargeData()
    },
    vida_utilProp() {
      this.chargeData()
    },
    prox_revisionProp() {
      this.chargeData()
    },
    is_epiProp() {
      this.chargeData()
    },
    userProp() {
      this.chargeData()
    },
    dateIniProp() {
      this.chargeData()
    },
    dateFinProp() {
      this.chargeData()
    },
  },
  methods: {
    ...mapActions({
      getDashboardAssets: 'actions/getDashboardAssets',
    }),
    chargeData() {
      const clienteCurrent = this.currentClient ? this.currentClient.id : ''
      const client = this.clientprop && this.clientprop !== '0' ? this.clientprop : clienteCurrent

      const category = (this.categoryId) ? this.categoryId : ''
      const search = (this.searchProp) ? this.searchProp : ''
      const statusSelected = []
      if (this.statusProp && this.statusProp.length > 0) {
        this.statusProp.forEach(element => {
          statusSelected.push(element.id)
        })
      }
      const vida_util = (this.vida_utilProp) ? this.vida_utilProp : ''
      const prox_revision = (this.prox_revisionProp) ? this.prox_revisionProp : ''
      const is_epi = (this.is_epiProp) ? this.is_epiProp : ''
      const user = (this.userProp) ? this.userProp : ''
      const location = (this.locationProp) ? this.locationProp : ''
      const albaran = (this.albaranProp) ? this.albaranProp : ''

      this.getDashboardAssets({
        client,
        category,
        search,
        status: statusSelected,
        vida_util,
        prox_revision,
        is_epi,
        user,
        date_ini: this.dateIniProp,
        date_fin: this.dateFinProp,
        location,
        num_albaran: albaran,
      })
    },
  },
  async created() {
    await this.chargeData()
  },
}
</script>
<style lang="scss" >
</style>
